export default {
  mounted: function () {
    if (!this.$route.query.table) {
      this.getProducts();
    }
    if (this.originName) {
      this.getProducts(this.originName);
    }
  },

  watch: {
    originName() {
      this.getProducts(this.originName);
    },
  },

  computed: {
    getMetaOriginName() {
      if (this.$route.meta.originName) {
        if (this.$store.state.menu.profile.isKioskModeEnabled) {
          console.log(`Kiosk mode DETECTED!, changing origin to BKiosk...`);
          return "BKIOSK";
        } else return this.$route.meta.originName;
      }
      if (typeof this.$route.meta === "function") {
        if (this.$route.meta().originName) {
          if (this.$store.state.menu.profile.isKioskModeEnabled) {
            console.log(`Kiosk mode DETECTED!, changing origin to BKiosk...`);
            return "BKIOSK";
          } else return this.$route.meta().originName;
        }
      }

      return undefined;
    },
    originName() {
      if (this.getMetaOriginName) {
        return this.getMetaOriginName;
      }
      return this.$store.getters["menu/originName"];
    },
  },

  methods: {
    getProducts(originName = null) {
      const queryString = {
        commerceId: this.$route.query.commerceId,
        originName:
          this.$route.query.originName || this.getMetaOriginName || originName,
        menuUUID: this.$route.query.menuUUID,
        table: this.$route.query.table,
      };

      this.$store.dispatch("menu/getProducts", queryString);
    },
  },
};
