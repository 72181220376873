<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";
import firebaseDeliveryBindOrder from "@/mixins/firebase/bindDeliveryOrder";
import getMenu from "@/mixins/getMenu";

export default {
  name: "ProductExtras",

  metaInfo: { title: "Agrega Extras!" },

  extends: View,

  mixins: [
    firebaseDeliveryBindOrder,
    getMenu,
    LoadSections([
      "layout-header-menu-with-return-button-and-title",
      "public-product-extras",
    ]),
  ],
};
</script>
